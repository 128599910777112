<script setup lang="ts">
import { computed } from 'vue'
import Icon from '@/components/partials/Icon.vue'
import { useLocalizedRouter } from '@/composables/localizedRouter'
import { useStore } from '@/stores'
import type { SearchedEstablishment } from '@/types/SearchedEstablishment'

type Props = {
  establishment: SearchedEstablishment
}

const props = defineProps<Props>()

const { localizedRoute } = useLocalizedRouter()

const store = useStore()

const establishmentUrl = computed(() => {
  const country = props.establishment.city.country.code.toLowerCase()

  const route = localizedRoute({
    name: 'establishment',
    params: {
      country: country !== store.state.session.country ? country : '',
      city: props.establishment.city.slug,
      slug: props.establishment.slug,
    },
  })

  return route
})
</script>

<template>
  <RouterLink :to="establishmentUrl" data-test-id="search-result">
    <div class="search-result">
      <div class="search-result__image" :style="{ 'background-image': `url(${establishment.image_url})`}" />
      <div class="search-result__text">
        <div class="search-result__title">
          {{ establishment.title }}
        </div>
        <div class="search-result__subtitle">
          {{ establishment.categories }}
        </div>
      </div>
      <Icon name="arrow-right-s-line" class="search-result__arrow" />
    </div>
  </RouterLink>
</template>

<style lang="scss" scoped>
.search-result {
  display: flex;
  gap: 1rem;
  padding: 1rem 0;
  align-items: center;

  &__image {
    flex: 0 0 3rem;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-size: cover;
  }

  &__text {
    flex: 1 1;
    // fixes flexbox issues on subtitle
    width: 100px;
    position: relative;
  }

  &__title {
    font-size: 1.125rem;
    font-weight: 700;
  }

  &__subtitle {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__arrow {
    margin-left: auto;
    flex: 0 0 1.315rem;

    &:deep(svg) {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
</style>
