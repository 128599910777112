<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from '@/stores'
import { useLocalizedRouter } from '@/composables/localizedRouter'
import NavBarDesktop from './NavBarDesktop/NavBarDesktop.vue'
import NavBarMobile from './NavBarMobile.vue'
import type { Establishment } from '@/http/models/Establishment'
import { Capacitor } from '@capacitor/core'

const route = useRoute()
const store = useStore()
const { localizedRoute } = useLocalizedRouter()

const establishmentInCart = computed<Establishment | null>(() => store.getters['cart/establishment'])
const onLocationIdentifier = computed<string | null>(() => store.getters['session/onLocationIdentifier'])
const isOnLocationDirect = computed(() => store.getters['session/isOnLocationDirect'])

const doNotShowMobileHeader = computed(() =>
  (route.name as string).includes('establishment')
  || (route.name as string).includes('thanks')
  || (route.name as string).includes('checkout')
  || (route.name as string).includes('slug')
  || (route.name as string).includes('marketplace')
)

const isMarketplacePage = computed(() =>
  (route.name as string).includes('marketplace')
  || (route.name as string).includes('slug')
)

const logoLink = computed(() => {
  const name = route.name as string

  if (name?.includes('checkout') && establishmentInCart.value !== null) {
    return localizedRoute({
      name: 'establishment',
      params: {
        country: establishmentInCart.value.location.country === store.state.session.country ? undefined : establishmentInCart.value.location.country,
        city: establishmentInCart.value.location.city,
        slug: establishmentInCart.value.slug,
        onLocationId: onLocationIdentifier.value ?? '',
        direct: isOnLocationDirect.value ? 'direct' : '',
      },
    })
  }

  return name.includes('marketplace')
    ? Capacitor.isNativePlatform() ? localizedRoute({ name: 'home' }) : '/'
    : localizedRoute({
      name: 'marketplace',
      query: store.getters['session/appEstablishmentsQueryString'],
    })
})

const isEstablishmentPage = computed(() => (route.name as string).includes('establishment'))
const isThanksPage = computed(() => (route.name as string).includes('thanks'))
const isCheckoutPage = computed(() => (route.name as string).includes('checkout'))
</script>

<template>
  <header data-test-id="navbar" :class="[ 'navbar', { 'navbar--marketplace': isMarketplacePage, 'navbar--establishment': isEstablishmentPage, 'navbar--thanks': isThanksPage, 'navbar--checkout': isCheckoutPage } ]">
    <NavBarDesktop :logo-link="logoLink" />

    <NavBarMobile :logo-link="logoLink"
                  v-if="!doNotShowMobileHeader" />
  </header>
</template>

<style lang="scss" scoped>
@import '@/assets/css/mixins/breakpoints-up.scss';
@import '@/assets/css/mixins/breakpoints-down.scss';

.navbar {
  $self: &;
  width: 100%;
  position: fixed;

  left: 0;
  top: 0;
  right: 0;
  z-index: 50;
  background-color: #fff;

  // We increase the height of the header and nudge it up, to prevent elements from showing up above the header when scrolling vigorously on iOs
  padding: 1rem 0 0 0;
  margin-top: -1rem;

  // place this div on a 3D layer
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;

  @include lg-up {
    position: absolute;
    background: none;
    margin-top: 5rem;
  }

  // Hide on establishment pages & mobile views
  &--establishment,
  &--checkout,
  &--thanks {
    display: none;

    @include lg-up {
      display: block;
    }
  }

  &--marketplace {
    @include lg-down {
      background: var(--color-bg-base);
    }
  }
}
</style>
